import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import sample from "lodash.sample"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Dropdown from "../components/atoms/dropdown"
import {
  trainingContainer,
  dropdownAll,
  dropdownCourse,
  dropdownConference,
  dropdownTutorial,
  caretAll,
  caretCourse,
  caretConference,
  caretTutorial,
  trainingBox,
  trainingBox__imageContainer,
  trainingBox__image,
  trainingBox__infoContainer,
  trainingBox__link,
  trainingBox__typeCourse,
  trainingBox__typeConference,
  trainingBox__typeTutorial,
  trainingBox__title,
  trainingBox__completionDate,
  trainingBox__description,
} from "../sass/learning-and-development.module.scss"

const optionsList = ["All", "Conference", "Course", "Tutorial"]

const avatars = [
  "blackWhiteAvatar",
  "blueAvatar",
  "blueRedAvatar",
  "blueWhiteAvatar",
  "brownAvatar",
  "cyanBlueAvatar",
  "greenAvatar",
  "greenBlueAvatar",
  "greenRedAvatar",
  "greenYellowAvatar",
  "orangeAvatar",
  "pinkAvatar",
  "purpleAvatar",
  "purpleGoldAvatar",
  "purpleOrangeAvatar",
  "redAvatar",
  "redBlackAvatar",
  "redYellowAvatar",
  "yellowAvatar",
]

const getTrainingTypeClass = type =>
  type === "Course"
    ? trainingBox__typeCourse
    : type === "Conference"
    ? trainingBox__typeConference
    : trainingBox__typeTutorial

const getDropdownStyle = type =>
  type === "Course"
    ? dropdownCourse
    : type === "Conference"
    ? dropdownConference
    : type === "Tutorial"
    ? dropdownTutorial
    : dropdownAll

const getCaretClassname = type =>
  type === "Course"
    ? caretCourse
    : type === "Conference"
    ? caretConference
    : type === "Tutorial"
    ? caretTutorial
    : caretAll

const TrainingBox = ({
  title,
  type,
  image,
  description,
  link,
  completionDate,
  defaultImage,
}) => {
  return (
    <div className={trainingBox}>
      {link && (
        <a
          className={trainingBox__link}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link
        </a>
      )}
      <div className={trainingBox__imageContainer}>
        <Img
          className={trainingBox__image}
          alt={title}
          fluid={image ? image.fluid : defaultImage}
        />
      </div>
      <div className={trainingBox__infoContainer}>
        <span className={getTrainingTypeClass(type)}>{type}</span>
        <div className={trainingBox__title}>{title}</div>
        <div className={trainingBox__completionDate}>
          Completion date: {completionDate}
        </div>
        <p className={trainingBox__description}>{description.description}</p>
      </div>
    </div>
  )
}

const LearningAndDevelopment = ({ data }) => {
  const allTrainings = data.allContentfulTraining.edges
  const allFluids = avatars.map(a => data[a].childImageSharp.fluid)

  const [dropdownValue, setDropdownValue] = useState("All")

  const trainings =
    dropdownValue === "All"
      ? allTrainings
      : allTrainings.filter(({ node }) => node.type === dropdownValue)

  const handleDropdownChange = e => {
    setDropdownValue(e.target.value)
  }

  return (
    <Layout>
      <SEO title="Guillaume Bouffard | Learning and development" />
      <div className={trainingContainer}>
        <Dropdown
          label="Filter by: "
          options={optionsList}
          selected={dropdownValue}
          onChange={handleDropdownChange}
          classname={getDropdownStyle(dropdownValue)}
          caretClassname={getCaretClassname(dropdownValue)}
        />
        {trainings.map(({ node }) => (
          <TrainingBox
            key={node.title}
            {...node}
            defaultImage={sample(allFluids)}
          />
        ))}
      </div>
    </Layout>
  )
}

export default LearningAndDevelopment

export const query = graphql`
  query learningAndDevelopmentQuery {
    allContentfulTraining {
      edges {
        node {
          title
          type
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
          link
          completionDate
        }
      }
    }
    redAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#f00e2e"
            shadow: "#192550"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    blueAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#6495ed"
            shadow: "#000080"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    greenAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#00ff7f"
            shadow: "#000000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    yellowAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#ffd700"
            shadow: "#000000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    orangeAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#ffa500"
            shadow: "#000000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    purpleAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#ba55d3"
            shadow: "#000000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    pinkAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#ff1493"
            shadow: "#000000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    brownAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#deb887"
            shadow: "#800000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    purpleGoldAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#ffd700"
            shadow: "#4b0082"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    greenRedAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#228b22"
            shadow: "#8b0000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    blueWhiteAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#6495ed"
            shadow: "#fffafa"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    blackWhiteAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#000000"
            shadow: "#FFFFFF"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    purpleOrangeAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#4b0082"
            shadow: "#ff8c00"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    cyanBlueAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#00008b"
            shadow: "#00ffff"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    redYellowAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#dc143c"
            shadow: "#f0e68c"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    blueRedAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#191970"
            shadow: "#ff0000"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    greenYellowAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#32cd32"
            shadow: "#ffff00"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    redBlackAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#000000"
            shadow: "#b22222"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
    greenBlueAvatar: file(relativePath: { eq: "gbouffard.png" }) {
      childImageSharp {
        fluid(
          base64Width: 600
          duotone: {
            highlight: "#228b22"
            shadow: "#0000cd"
          }
        ) {
          base64
          aspectRatio
        }
      }
    }
  }
`
